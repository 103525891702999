<template>
  <div>
    <Header
      v-if="!defaultHeaderDisabled"
      :page-title="pageTitle"
      :bg-color="bgColor"
      :font-color="fontColor"
      :tagline="tagline"
      :icon-one="iconOne"
      :icon-two="iconTwo"
    ></Header>
    <div
      class="tw-h-full tw-items-center tw-bg-white tw-flex tw-flex-col tw-justify-between tw-relative tw-rounded-t-3xl tw--mt-5"
      v-if="trip"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="save">
          <div class="tw-px-10 tw-pt-10 tw-pb-14">
            <h3
              class="tw-text-25 tw-text-dark-green tw-font-semibold tw-text-left"
            >
              Customize your invitation
            </h3>
            <p
              class="tw-text-light-grey tw-text-base tw-leading-22 tw-text-left tw-mb-10"
            >
              This invite will generate a unique link to share with your crew.
              You can edit this invite any time by going to the Crew page.
            </p>
            <h4 class="tw-text-dark-green tw-font-semibold tw-text-left">
              Choose a design
            </h4>
            <p
              class="tw-text-light-grey tw-text-base tw-leading-22 tw-text-left tw-mb-6"
            >
              {{
                trip.isPremium
                  ? "Your trip has access to First Class designs!"
                  : "Upgrade your trip to access First Class designs."
              }}
            </p>
            <div class="tw-flex tw-mt-4 tw-flex-wrap">
              <div
                class="tw-p-1 tw-rounded-full tw-mb-6 tw-w-12 tw-h-12 tw-bg-transparent tw-mr-3 tw-cursor-pointer"
                :class="{ 'tw-bg-dark-green': activeDesign == designs.DEFAULT }"
                @click="activeDesign = designs.DEFAULT"
              >
                <img
                  src="@/assets/svgs/designs/default-jetty.svg"
                  alt="default"
                />
              </div>
              <div
                class="tw-rounded-full tw-mb-6 tw-w-12 tw-h-12 tw-border-solid tw-border-4 tw-border-transparent tw-mr-3 tw-cursor-pointer"
                :class="{
                  'tw-border-dark-green': activeDesign == designs.COUNTRY
                }"
                @click="activeDesign = designs.COUNTRY"
              >
                <img
                  src="@/assets/svgs/designs/country.svg"
                  :alt="designs.COUNTRY"
                  class="tw-bg-dark-green tw-rounded-full"
                  :class="{ 'tw-opacity-30': !trip.isPremium }"
                />
                <img
                  src="@/assets/svgs/premium/blue-diamond-solo.svg"
                  alt="Premium"
                  class="tw-mx-auto tw-mt-2"
                  v-if="trip.isPremium"
                />
                <img
                  src="@/assets/svgs/premium/premium-eye.svg"
                  alt="Premium"
                  class="tw-mx-auto tw--mt-6"
                  v-if="!trip.isPremium"
                />
              </div>
              <div
                class="tw-rounded-full tw-mb-6 tw-w-12 tw-h-12 tw-border-solid tw-border-4 tw-border-transparent tw-mr-3 tw-cursor-pointer"
                :class="{
                  'tw-border-dark-green': activeDesign == designs.SADDLE
                }"
                @click="activeDesign = designs.SADDLE"
              >
                <img
                  src="@/assets/svgs/designs/saddle.svg"
                  :alt="designs.SADDLE"
                  class="tw-bg-orange tw-rounded-full"
                  :class="{ 'tw-opacity-30': !trip.isPremium }"
                />
                <img
                  src="@/assets/svgs/premium/blue-diamond-solo.svg"
                  alt="Premium"
                  class="tw-mx-auto tw-mt-2"
                  v-if="trip.isPremium"
                />
                <img
                  src="@/assets/svgs/premium/premium-eye.svg"
                  alt="Premium"
                  class="tw-mx-auto tw--mt-6"
                  v-if="!trip.isPremium"
                />
              </div>
              <div
                class="tw-rounded-full tw-mb-6 tw-w-12 tw-h-12 tw-border-solid tw-border-4 tw-border-transparent tw-mr-3 tw-cursor-pointer"
                :class="{
                  'tw-border-dark-green': activeDesign == designs.BIRTHDAY
                }"
                @click="activeDesign = designs.BIRTHDAY"
              >
                <img
                  src="@/assets/svgs/designs/birthday.svg"
                  :alt="designs.BIRTHDAY"
                  class="tw-bg-j-pink tw-rounded-full"
                  :class="{ 'tw-opacity-30': !trip.isPremium }"
                />
                <img
                  src="@/assets/svgs/premium/blue-diamond-solo.svg"
                  alt="Premium"
                  class="tw-mx-auto tw-mt-2"
                  v-if="trip.isPremium"
                />
                <img
                  src="@/assets/svgs/premium/premium-eye.svg"
                  alt="Premium"
                  class="tw-mx-auto tw--mt-6"
                  v-if="!trip.isPremium"
                />
              </div>
              <div
                class="tw-rounded-full tw-mb-6 tw-w-12 tw-h-12 tw-border-solid tw-border-4 tw-border-transparent tw-mr-3 tw-cursor-pointer"
                :class="{
                  'tw-border-dark-green': activeDesign == designs.SIMPLE
                }"
                @click="activeDesign = designs.SIMPLE"
              >
                <img
                  src="@/assets/svgs/designs/simple.png"
                  :alt="designs.SIMPLE"
                  class="tw-rounded-full"
                  :class="{ 'tw-opacity-30': !trip.isPremium }"
                />
                <img
                  src="@/assets/svgs/premium/blue-diamond-solo.svg"
                  alt="Premium"
                  class="tw-mx-auto tw-mt-2"
                  v-if="trip.isPremium"
                />
                <img
                  src="@/assets/svgs/premium/premium-eye.svg"
                  alt="Premium"
                  class="tw-mx-auto tw--mt-6"
                  v-if="!trip.isPremium"
                />
              </div>
              <div
                class="tw-rounded-full tw-mb-6 tw-w-12 tw-h-12 tw-border-solid tw-border-4 tw-border-transparent tw-mr-3 tw-cursor-pointer"
                :class="{
                  'tw-border-dark-green': activeDesign == designs.PINK_MIMOSA
                }"
                @click="activeDesign = designs.PINK_MIMOSA"
              >
                <img
                  src="@/assets/svgs/designs/pink-mimosa-icon.svg"
                  :alt="designs.PINK_MIMOSA"
                  class="tw-rounded-full"
                  :class="{ 'tw-opacity-30': !trip.isPremium }"
                />
                <img
                  src="@/assets/svgs/premium/blue-diamond-solo.svg"
                  alt="Premium"
                  class="tw-mx-auto tw-mt-2"
                  v-if="trip.isPremium"
                />
                <img
                  src="@/assets/svgs/premium/premium-eye.svg"
                  alt="Premium"
                  class="tw-mx-auto tw--mt-6"
                  v-if="!trip.isPremium"
                />
              </div>
              <div
                class="tw-rounded-full tw-mb-6 tw-w-12 tw-h-12 tw-border-solid tw-border-4 tw-border-transparent tw-mr-3 tw-cursor-pointer"
                :class="{
                  'tw-border-dark-green': activeDesign == designs.BLUE_PALM
                }"
                @click="activeDesign = designs.BLUE_PALM"
              >
                <img
                  src="@/assets/svgs/designs/blue-palms-icon.svg"
                  :alt="designs.BLUE_PALM"
                  class="tw-rounded-full"
                  :class="{ 'tw-opacity-30': !trip.isPremium }"
                />
                <img
                  src="@/assets/svgs/premium/blue-diamond-solo.svg"
                  alt="Premium"
                  class="tw-mx-auto tw-mt-2"
                  v-if="trip.isPremium"
                />
                <img
                  src="@/assets/svgs/premium/premium-eye.svg"
                  alt="Premium"
                  class="tw-mx-auto tw--mt-6"
                  v-if="!trip.isPremium"
                />
              </div>
              <div
                class="tw-rounded-full tw-mb-6 tw-w-12 tw-h-12 tw-border-solid tw-border-4 tw-border-transparent tw-mr-3 tw-cursor-pointer"
                :class="{
                  'tw-border-dark-green': activeDesign == designs.PURPLE_COW
                }"
                @click="activeDesign = designs.PURPLE_COW"
              >
                <img
                  src="@/assets/svgs/designs/purple-cow-icon.svg"
                  :alt="designs.PURPLE_COW"
                  class="tw-rounded-full"
                  :class="{ 'tw-opacity-30': !trip.isPremium }"
                />
                <img
                  src="@/assets/svgs/premium/blue-diamond-solo.svg"
                  alt="Premium"
                  class="tw-mx-auto tw-mt-2"
                  v-if="trip.isPremium"
                />
                <img
                  src="@/assets/svgs/premium/premium-eye.svg"
                  alt="Premium"
                  class="tw-mx-auto tw--mt-6"
                  v-if="!trip.isPremium"
                />
              </div>
              <div
                class="tw-rounded-full tw-mb-6 tw-w-12 tw-h-12 tw-border-solid tw-border-4 tw-border-transparent tw-mr-3 tw-cursor-pointer"
                :class="{
                  'tw-border-dark-green': activeDesign == designs.GREEN_PALM
                }"
                @click="activeDesign = designs.GREEN_PALM"
              >
                <img
                  src="@/assets/svgs/designs/palm.jpg"
                  :alt="designs.GREEN_PALM"
                  class="tw-rounded-full"
                  :class="{ 'tw-opacity-30': !trip.isPremium }"
                />
                <img
                  src="@/assets/svgs/premium/blue-diamond-solo.svg"
                  alt="Premium"
                  class="tw-mx-auto tw-mt-2"
                  v-if="trip.isPremium"
                />
                <img
                  src="@/assets/svgs/premium/premium-eye.svg"
                  alt="Premium"
                  class="tw-mx-auto tw--mt-6"
                  v-if="!trip.isPremium"
                />
              </div>
              <div
                class="tw-rounded-full tw-mb-6 tw-w-12 tw-h-12 tw-border-solid tw-border-4 tw-border-transparent tw-mr-3 tw-cursor-pointer"
                :class="{
                  'tw-border-dark-green': activeDesign == designs.RIP
                }"
                @click="activeDesign = designs.RIP"
              >
                <img
                  src="@/assets/svgs/designs/rip.svg"
                  :alt="designs.RIP"
                  class="tw-rounded-full"
                  :class="{ 'tw-opacity-30': !trip.isPremium }"
                />
                <img
                  src="@/assets/svgs/premium/blue-diamond-solo.svg"
                  alt="Premium"
                  class="tw-mx-auto tw-mt-2"
                  v-if="trip.isPremium"
                />
                <img
                  src="@/assets/svgs/premium/premium-eye.svg"
                  alt="Premium"
                  class="tw-mx-auto tw--mt-6"
                  v-if="!trip.isPremium"
                />
              </div>
            </div>
          </div>
          <template v-if="activeDesign === designs.GREEN_PALM">
            <div class="tw-py-6">
              <div class="j-green-palm tw-pt-4">
                <img
                  src="@/assets/svgs/designs/green-palm-invited.svg"
                  alt="You're invited"
                  class="tw-mx-auto tw-block tw-pb-2"
                />
                <div
                  class="tw-mt-14 tw-mx-12 tw-px-10 tw-pt-4 tw-pb-8 tw-bg-white"
                >
                  <p
                    for="trip"
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Trip
                  </p>
                  <validation-provider v-slot="{ errors }" ref="tripTitle">
                    <j-text-field
                      v-if="trip"
                      v-model="trip.title"
                      centered-label="true"
                      :error-messages="errors"
                      background-color="#fafafa"
                      :customClasses="['tw-text-center']"
                    />
                  </validation-provider>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-2 tw-font-semibold"
                  >
                    Message
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Message"
                    rules="max:250"
                  >
                    <j-textarea
                      v-model="tripData.message"
                      outlined
                      rounded
                      background-color="#fafafa"
                      :error-messages="errors"
                      :placeholder="placeholderMessage"
                      @focus="placeholderMessage = ''"
                    />
                  </validation-provider>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-2 tw-font-semibold"
                  >
                    Hosted by
                  </p>
                  <p
                    class="tw-text-20 tw-text-dark-green-two tw-font-semibold tw-mb-0 tw-text-ellipsis tw-w-11/12 tw-mx-auto tw-truncate"
                  >
                    {{ tripData.fromName }}
                  </p>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Date
                  </p>
                  <p class="tw-text-20 tw-text-dark-green-two tw-font-semibold">
                    {{
                      trip && trip.startDate && trip.endDate
                        ? getFormattedDateRange(trip.startDate, trip.endDate)
                        : placeholderText
                    }}
                  </p>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Destination
                  </p>
                  <p
                    class="tw-text-20 tw-text-dark-green-two tw-font-semibold tw-mb-0 tw-text-ellipsis tw-w-11/12 tw-mx-auto tw-truncate"
                    v-if="destination"
                  >
                    {{ destination ? destination : placeholderText }}
                  </p>
                </div>
              </div>

              <div class="tw-mx-14 tw-relative">
                <validation-provider v-slot="{ errors }" rules="required">
                  <p class="tw-text-dark-green-two tw-my-0 tw-font-semibold">
                    RSVP Deadline *
                  </p>
                  <p class="tw-text-dark-green-two tw-text-xxs">
                    Your crew has until this date to join your trip.
                  </p>
                  <v-expansion-panels class="tw-rounded-md">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div
                          class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                        >
                          <v-icon>mdi-calendar-blank</v-icon>
                          <div
                            class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                          >
                            <span v-if="tripData.rsvpDeadline">{{
                              getFormattedDate(
                                tripData.rsvpDeadline,
                                "without-time"
                              )
                            }}</span>
                            <span v-else>Choose a date</span>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="tw-overflow-visible">
                        <v-date-picker
                          width="230"
                          class="tw-py-4"
                          no-title
                          color="secondary"
                          v-model="tripData.rsvpDeadline"
                          :error-messages="errors"
                          :min="minDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="secondary"
                            class="tw-tracking-normal"
                            @click="tripData.rsvpDeadline = null"
                            >Reset Date</v-btn
                          >
                        </v-date-picker>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </validation-provider>
                <p
                  class="tw-text-dark-green-two tw-font-semibold tw-text-center tw-mt-6 tw-mb-2"
                >
                  How to Edit Trip Info on Invite
                </p>
                <p
                  class="tw-text-dark-green-two tw-font-normal tw-text-sm tw-text-center tw-mb-12"
                >
                  Go to the trip hub to update dates and destination. Any
                  changes will automatically reflect with your invite URL.
                </p>
                <v-btn
                  :disabled="invalid"
                  type="submit"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-if="trip.isPremium"
                  >{{ isEditMode ? "Save" : "Create Invitation" }}</v-btn
                >
                <v-btn
                  :disabled="invalid"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-else
                  @click="upgradeTrip()"
                  >Upgrade Trip and Save Invite</v-btn
                >
                <button
                  class="btn-tertiary tw-text-dark-green tw-tracking-normal tw-mx-auto tw-block tw-mt-4"
                  @click="skipCreation"
                >
                  Skip and Create Later
                </button>
              </div>
            </div>
          </template>
          <template v-if="activeDesign === designs.PURPLE_COW">
            <div class="tw-py-6">
              <div class="j-purple-cow tw-pt-4">
                <img
                  src="@/assets/svgs/designs/cow-invited.svg"
                  alt="You're invited"
                  class="tw-mx-auto tw-block tw-pb-2"
                />
                <div
                  class="tw-mt-14 tw-mx-12 tw-px-10 tw-pt-4 tw-pb-8 tw-bg-white"
                >
                  <p
                    for="trip"
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Trip
                  </p>
                  <validation-provider v-slot="{ errors }" ref="tripTitle">
                    <j-text-field
                      v-if="trip"
                      v-model="trip.title"
                      centered-label="true"
                      :error-messages="errors"
                      background-color="#fafafa"
                      :customClasses="['tw-text-center']"
                    />
                  </validation-provider>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-2 tw-font-semibold"
                  >
                    Message
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Message"
                    rules="max:250"
                  >
                    <j-textarea
                      v-model="tripData.message"
                      outlined
                      rounded
                      background-color="#fafafa"
                      :error-messages="errors"
                      :placeholder="placeholderMessage"
                      @focus="placeholderMessage = ''"
                    />
                  </validation-provider>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-2 tw-font-semibold"
                  >
                    Hosted by
                  </p>
                  <p
                    class="tw-text-20 tw-text-dark-green-two tw-font-semibold tw-mb-0 tw-text-ellipsis tw-w-11/12 tw-mx-auto tw-truncate"
                  >
                    {{ tripData.fromName }}
                  </p>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Date
                  </p>
                  <p class="tw-text-20 tw-text-dark-green-two tw-font-semibold">
                    {{
                      trip && trip.startDate && trip.endDate
                        ? getFormattedDateRange(trip.startDate, trip.endDate)
                        : placeholderText
                    }}
                  </p>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Destination
                  </p>
                  <p
                    class="tw-text-20 tw-text-dark-green-two tw-font-semibold tw-mb-0 tw-text-ellipsis tw-w-11/12 tw-mx-auto tw-truncate"
                    v-if="destination"
                  >
                    {{ destination ? destination : placeholderText }}
                  </p>
                </div>
              </div>

              <div class="tw-mx-14 tw-relative">
                <validation-provider v-slot="{ errors }" rules="required">
                  <p class="tw-text-dark-green-two tw-my-0 tw-font-semibold">
                    RSVP Deadline *
                  </p>
                  <p class="tw-text-dark-green-two tw-text-xxs">
                    Your crew has until this date to join your trip.
                  </p>
                  <v-expansion-panels class="tw-rounded-md">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div
                          class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                        >
                          <v-icon>mdi-calendar-blank</v-icon>
                          <div
                            class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                          >
                            <span v-if="tripData.rsvpDeadline">{{
                              getFormattedDate(
                                tripData.rsvpDeadline,
                                "without-time"
                              )
                            }}</span>
                            <span v-else>Choose a date</span>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="tw-overflow-visible">
                        <v-date-picker
                          width="230"
                          class="tw-py-4"
                          no-title
                          color="secondary"
                          v-model="tripData.rsvpDeadline"
                          :error-messages="errors"
                          :min="minDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="secondary"
                            class="tw-tracking-normal"
                            @click="tripData.rsvpDeadline = null"
                            >Reset Date</v-btn
                          >
                        </v-date-picker>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </validation-provider>
                <p
                  class="tw-text-dark-green-two tw-font-semibold tw-text-center tw-mt-6 tw-mb-2"
                >
                  How to Edit Trip Info on Invite
                </p>
                <p
                  class="tw-text-dark-green-two tw-font-normal tw-text-sm tw-text-center tw-mb-12"
                >
                  Go to the trip hub to update dates and destination. Any
                  changes will automatically reflect with your invite URL.
                </p>
                <v-btn
                  :disabled="invalid"
                  type="submit"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-if="trip.isPremium"
                  >{{ isEditMode ? "Save" : "Create Invitation" }}</v-btn
                >
                <v-btn
                  :disabled="invalid"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-else
                  @click="upgradeTrip()"
                  >Upgrade Trip and Save Invite</v-btn
                >
                <button
                  class="btn-tertiary tw-text-dark-green tw-tracking-normal tw-mx-auto tw-block tw-mt-4"
                  @click="skipCreation"
                >
                  Skip and Create Later
                </button>
              </div>
            </div>
          </template>
          <template v-if="activeDesign === designs.SIMPLE">
            <div class="tw-py-6">
              <div class="j-simple">
                <img
                  src="@/assets/svgs/designs/simple-invited.svg"
                  alt="You're invited"
                  class="tw-mx-auto tw-block tw-pb-2"
                />
                <div class="tw-mt-14 tw-mx-12 tw-px-10 tw-py-8 tw-bg-white">
                  <p
                    for="trip"
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Trip
                  </p>
                  <validation-provider v-slot="{ errors }" ref="tripTitle">
                    <j-text-field
                      v-if="trip"
                      v-model="trip.title"
                      centered-label="true"
                      :error-messages="errors"
                      background-color="#fafafa"
                      :customClasses="['tw-text-center']"
                    />
                  </validation-provider>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-2 tw-font-semibold"
                  >
                    Message
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Message"
                    rules="max:250"
                  >
                    <j-textarea
                      v-model="tripData.message"
                      outlined
                      rounded
                      background-color="#fafafa"
                      :error-messages="errors"
                      :placeholder="placeholderMessage"
                      @focus="placeholderMessage = ''"
                    />
                  </validation-provider>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-2 tw-font-semibold"
                  >
                    Hosted by
                  </p>
                  <p
                    class="tw-text-20 tw-text-dark-green-two tw-font-semibold tw-mb-0 tw-text-ellipsis tw-w-11/12 tw-mx-auto tw-truncate"
                  >
                    {{ tripData.fromName }}
                  </p>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Date
                  </p>
                  <p class="tw-text-20 tw-text-dark-green-two tw-font-semibold">
                    {{
                      trip && trip.startDate && trip.endDate
                        ? getFormattedDateRange(trip.startDate, trip.endDate)
                        : placeholderText
                    }}
                  </p>
                  <p
                    class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Destination
                  </p>
                  <p
                    class="tw-text-20 tw-text-dark-green-two tw-font-semibold tw-mb-0 tw-text-ellipsis tw-w-11/12 tw-mx-auto tw-truncate"
                    v-if="destination"
                  >
                    {{ destination ? destination : placeholderText }}
                  </p>
                </div>
              </div>

              <div class="tw-mx-14 tw-relative">
                <validation-provider v-slot="{ errors }" rules="required">
                  <p class="tw-text-dark-green-two tw-my-0 tw-font-semibold">
                    RSVP Deadline *
                  </p>
                  <p class="tw-text-dark-green-two tw-text-xxs">
                    Your crew has until this date to join your trip.
                  </p>
                  <v-expansion-panels class="tw-rounded-md">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div
                          class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                        >
                          <v-icon>mdi-calendar-blank</v-icon>
                          <div
                            class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                          >
                            <span v-if="tripData.rsvpDeadline">{{
                              getFormattedDate(
                                tripData.rsvpDeadline,
                                "without-time"
                              )
                            }}</span>
                            <span v-else>Choose a date</span>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="tw-overflow-visible">
                        <v-date-picker
                          width="230"
                          class="tw-py-4"
                          no-title
                          color="secondary"
                          v-model="tripData.rsvpDeadline"
                          :error-messages="errors"
                          :min="minDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="secondary"
                            class="tw-tracking-normal"
                            @click="tripData.rsvpDeadline = null"
                            >Reset Date</v-btn
                          >
                        </v-date-picker>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </validation-provider>
                <p
                  class="tw-text-dark-green-two tw-font-semibold tw-text-center tw-mt-6 tw-mb-2"
                >
                  How to Edit Trip Info on Invite
                </p>
                <p
                  class="tw-text-dark-green-two tw-font-normal tw-text-sm tw-text-center tw-mb-12"
                >
                  Go to the trip hub to update dates and destination. Any
                  changes will automatically reflect with your invite URL.
                </p>
                <v-btn
                  :disabled="invalid"
                  type="submit"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-if="trip.isPremium"
                  >{{ isEditMode ? "Save" : "Create Invitation" }}</v-btn
                >
                <v-btn
                  :disabled="invalid"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-else
                  @click="upgradeTrip()"
                  >Upgrade Trip and Save Invite</v-btn
                >
                <button
                  class="btn-tertiary tw-text-dark-green tw-tracking-normal tw-mx-auto tw-block tw-mt-4"
                  @click="skipCreation"
                >
                  Skip and Create Later
                </button>
              </div>
            </div>
          </template>
          <template v-if="activeDesign === designs.BIRTHDAY">
            <div class="j-checkers tw-py-14">
              <img
                src="@/assets/svgs/designs/birthday-invited.svg"
                alt="You're invited"
                class="tw-mx-auto tw-block tw-pb-2"
              />
              <div class="j-670 tw-mx-12 tw-px-14 tw-pt-20 j-wavy-invitation">
                <p
                  class="tw-text-dark-green-two tw-mt-2 tw-mb-1 tw-font-semibold"
                >
                  Trip
                </p>
                <validation-provider v-slot="{ errors }" ref="tripTitle">
                  <j-text-field
                    v-if="trip"
                    v-model="trip.title"
                    centered-label="true"
                    :error-messages="errors"
                    background-color="#fafafa"
                    :customClasses="['tw-text-center']"
                  />
                </validation-provider>
                <p
                  class="tw-text-dark-green-two tw-mt-4 tw-mb-1 tw-font-semibold"
                >
                  Message
                </p>
                <validation-provider
                  v-slot="{ errors }"
                  name="Message"
                  rules="max:250"
                >
                  <j-textarea
                    v-model="tripData.message"
                    outlined
                    rounded
                    background-color="#fafafa"
                    :error-messages="errors"
                    :placeholder="placeholderMessage"
                    @focus="placeholderMessage = ''"
                  />
                </validation-provider>
                <p
                  class="tw-text-dark-green-two tw-mt-6 tw--mb-2 tw-font-semibold"
                >
                  Hosted by
                </p>
                <p
                  class="tw-text-20 tw-text-dark-green-two tw-font-semibold tw-mb-0 tw-text-ellipsis tw-w-11/12 tw-mx-auto tw-truncate"
                >
                  {{ tripData.fromName }}
                </p>
                <p
                  class="tw-text-dark-green-two tw-mt-4 tw--mb-2 tw-font-semibold"
                >
                  Date
                </p>
                <p
                  class="tw-text-20 tw-text-dark-green-two tw-font-semibold tw-mb-4"
                >
                  {{
                    trip && trip.startDate && trip.endDate
                      ? getFormattedDateRange(trip.startDate, trip.endDate)
                      : placeholderText
                  }}
                </p>
                <p
                  class="tw-text-dark-green-two tw-mt-4 tw--mb-2 tw-font-semibold"
                >
                  Destination
                </p>
                <p
                  class="tw-text-20 tw-text-dark-green-two tw-font-semibold tw-mb-0 tw-text-ellipsis tw-w-11/12 tw-mx-auto tw-truncate"
                  v-if="destination"
                >
                  {{ destination ? destination : placeholderText }}
                </p>
              </div>

              <div class="tw-mx-12">
                <validation-provider v-slot="{ errors }" rules="required">
                  <p class="tw-text-white tw-mt-12 tw-mb-0 tw-font-semibold">
                    RSVP Deadline *
                  </p>
                  <p class="tw-text-white tw-text-xxs">
                    Your crew has until this date to join your trip.
                  </p>
                  <v-expansion-panels class="tw-rounded-md">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div
                          class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                        >
                          <v-icon>mdi-calendar-blank</v-icon>
                          <div
                            class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                          >
                            <span v-if="tripData.rsvpDeadline">{{
                              getFormattedDate(
                                tripData.rsvpDeadline,
                                "without-time"
                              )
                            }}</span>
                            <span v-else>Choose a date</span>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-date-picker
                          width="230"
                          class="tw-py-4"
                          no-title
                          color="secondary"
                          v-model="tripData.rsvpDeadline"
                          :error-messages="errors"
                          :min="minDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="secondary"
                            class="tw-tracking-normal"
                            @click="tripData.rsvpDeadline = null"
                            >Reset Date</v-btn
                          >
                        </v-date-picker>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </validation-provider>
                <p
                  class="tw-text-white tw-font-semibold tw-text-center tw-mt-8 tw-mb-2"
                >
                  How to Edit Trip Info on Invite
                </p>
                <p
                  class="tw-text-white tw-font-normal tw-text-sm tw-text-center tw-mb-12"
                >
                  Go to the trip hub to update dates and destination. Any
                  changes will automatically reflect with your invite URL.
                </p>
                <v-btn
                  :disabled="invalid"
                  type="submit"
                  class="tw-tracking-normal tw-bg-purple tw-text-white"
                  :class="{
                    'j-premium tw-text-white': trip.isPremium && !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-if="trip.isPremium"
                  >{{ isEditMode ? "Save" : "Create Invitation" }}</v-btn
                >
                <v-btn
                  :disabled="invalid"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-else
                  @click="upgradeTrip()"
                  >Upgrade Trip and Save Invite</v-btn
                >
                <button
                  class="btn-tertiary tw-text-dark-green tw-tracking-normal tw-mx-auto tw-block tw-mt-4"
                  @click="skipCreation"
                >
                  Skip and Create Later
                </button>
              </div>
            </div>
          </template>
          <template v-if="activeDesign === designs.SADDLE">
            <div class="tw-bg-orange tw-py-14">
              <img
                src="@/assets/svgs/designs/saddle-invited.svg"
                alt="You're invited"
                class="tw-mx-auto tw-block tw-pb-2"
              />
              <img
                src="@/assets/svgs/designs/saddle-figure.svg"
                alt="You're invited"
                class="tw-mx-auto tw-block tw-pb-6"
              />
              <div
                class="tw-mx-4 custom-rounded tw-border-purple tw-border-t-11 tw-border-l-11 tw-border-r-11 tw-border-solid"
              >
                <div
                  class="custom-rounded tw-border-light-purple tw-border-t-11 tw-border-l-11 tw-border-r-11 tw-border-solid"
                >
                  <div
                    class="custom-rounded tw-border-dark-orange tw-border-t-11 tw-border-l-11 tw-border-r-11 tw-border-solid"
                  >
                    <div class="custom-rounded tw-bg-white tw-p-4 tw-pb-12">
                      <img
                        src="@/assets/svgs/designs/saddle-ball.svg"
                        alt="Invitation"
                        class="tw-block tw-mx-auto"
                      />
                      <p
                        for="trip"
                        class="tw-text-dark-green-two tw-mt-4 tw-mb-2 tw-font-semibold"
                      >
                        Trip
                      </p>
                      <validation-provider v-slot="{ errors }" ref="tripTitle">
                        <j-text-field
                          v-if="trip"
                          v-model="trip.title"
                          centered-label="true"
                          :error-messages="errors"
                          background-color="#fafafa"
                          :customClasses="['tw-text-center']"
                        />
                      </validation-provider>
                      <p
                        class="tw-text-dark-green-two tw-mt-6 tw-mb-2 tw-font-semibold"
                      >
                        Message
                      </p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Message"
                        rules="max:250"
                      >
                        <j-textarea
                          v-model="tripData.message"
                          outlined
                          rounded
                          background-color="#fafafa"
                          :error-messages="errors"
                          :placeholder="placeholderMessage"
                          @focus="placeholderMessage = ''"
                        />
                      </validation-provider>
                      <p
                        class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                      >
                        Hosted by
                      </p>
                      <p
                        class="tw-text-20 tw-text-green tw-font-semibold tw-mb-0"
                      >
                        {{ tripData.fromName }}
                      </p>
                      <p
                        class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                      >
                        Date
                      </p>
                      <p
                        class="tw-text-20 tw-text-green tw-font-semibold tw-mb-4"
                      >
                        {{
                          trip && trip.startDate && trip.endDate
                            ? getFormattedDateRange(
                                trip.startDate,
                                trip.endDate
                              )
                            : placeholderText
                        }}
                      </p>
                      <p
                        class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                      >
                        Destination
                      </p>
                      <p
                        class="tw-text-20 tw-text-green tw-font-semibold tw-mb-0"
                        v-if="destination"
                      >
                        {{ destination ? destination : placeholderText }}
                      </p>
                      <validation-provider v-slot="{ errors }" rules="required">
                        <p
                          class="tw-text-dark-green-two tw-mt-6 tw-mb-0 tw-font-semibold"
                        >
                          RSVP Deadline *
                        </p>
                        <p class="tw-text-dark-green-two tw-text-xxs">
                          Your crew has until this date to join your trip.
                        </p>
                        <v-expansion-panels class="tw-rounded-md">
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <div
                                class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                              >
                                <v-icon>mdi-calendar-blank</v-icon>
                                <div
                                  class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                                >
                                  <span v-if="tripData.rsvpDeadline">{{
                                    getFormattedDate(
                                      tripData.rsvpDeadline,
                                      "without-time"
                                    )
                                  }}</span>
                                  <span v-else>Choose a date</span>
                                </div>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-date-picker
                                width="230"
                                class="tw-py-4"
                                no-title
                                color="secondary"
                                v-model="tripData.rsvpDeadline"
                                :error-messages="errors"
                                :min="minDate"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="secondary"
                                  class="tw-tracking-normal"
                                  @click="tripData.rsvpDeadline = null"
                                  >Reset Date</v-btn
                                >
                              </v-date-picker>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-mx-12">
                <p
                  class="tw-text-white tw-font-semibold tw-text-left tw-mt-8 tw-mb-2"
                >
                  How to Edit Trip Info on Invite
                </p>
                <p
                  class="tw-text-white tw-font-normal tw-text-sm tw-text-left tw-mb-12"
                >
                  Go to the trip hub to update dates and destination. Any
                  changes will automatically reflect with your invite URL.
                </p>
                <v-btn
                  :disabled="invalid"
                  type="submit"
                  class="tw-tracking-normal tw-bg-purple tw-text-white"
                  :class="{
                    'j-premium tw-text-white': trip.isPremium && !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-if="trip.isPremium"
                  >{{ isEditMode ? "Save" : "Create Invitation" }}</v-btn
                >
                <v-btn
                  :disabled="invalid"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-else
                  @click="upgradeTrip()"
                  >Upgrade Trip and Save Invite</v-btn
                >
                <button
                  class="btn-tertiary tw-text-dark-green tw-tracking-normal tw-mx-auto tw-block tw-mt-4"
                  @click="skipCreation"
                >
                  Skip and Create Later
                </button>
              </div>
            </div>
          </template>
          <template v-if="activeDesign === designs.COUNTRY">
            <div class="tw-bg-green tw-pt-14">
              <img
                src="@/assets/svgs/designs/country-invited.svg"
                alt="You're invited"
                class="tw-mx-auto tw-block tw-pb-14"
              />
              <div class="tw-p-2 tw-bg-white tw-mx-4">
                <div
                  class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-border-green tw-border-3 tw-border-solid"
                >
                  <img
                    src="@/assets/svgs/designs/country-glass.svg"
                    alt="Country glass"
                  />
                  <p for="trip" class="tw-text-green tw-my-2 tw-font-semibold">
                    Trip
                  </p>
                  <validation-provider v-slot="{ errors }" ref="tripTitle">
                    <j-text-field
                      v-if="trip"
                      v-model="trip.title"
                      centered-label="true"
                      :error-messages="errors"
                      background-color="#fafafa"
                      :customClasses="['tw-text-center']"
                    />
                  </validation-provider>
                  <p class="tw-text-green tw-mt-6 tw-mb-2 tw-font-semibold">
                    Message
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Message"
                    rules="max:250"
                  >
                    <j-textarea
                      v-model="tripData.message"
                      outlined
                      rounded
                      background-color="#fafafa"
                      :error-messages="errors"
                      :placeholder="placeholderMessage"
                      @focus="placeholderMessage = ''"
                    />
                  </validation-provider>
                  <p class="tw-text-green tw-mt-6 tw-mb-0 tw-font-semibold">
                    Hosted by
                  </p>
                  <p class="tw-text-22 tw-text-green tw-font-semibold tw-mb-0">
                    {{ tripData.fromName }}
                  </p>

                  <p class="tw-text-green tw-mt-6 tw-mb-0 tw-font-semibold">
                    Date
                  </p>
                  <p class="tw-text-22 tw-text-green tw-font-semibold tw-mb-4">
                    {{
                      trip && trip.startDate && trip.endDate
                        ? getFormattedDateRange(trip.startDate, trip.endDate)
                        : placeholderText
                    }}
                  </p>
                  <p class="tw-text-green tw-mt-6 tw-mb-0 tw-font-semibold">
                    Destination
                  </p>
                  <p
                    class="tw-text-22 tw-text-green tw-font-semibold tw-mb-0"
                    v-if="destination"
                  >
                    {{ destination ? destination : placeholderText }}
                  </p>
                </div>
              </div>
              <div class="tw-mx-12 tw-mt-14 tw-pb-4">
                <validation-provider v-slot="{ errors }" rules="required">
                  <p
                    class="tw-text-left tw-text-white tw-font-semibold tw-mb-0"
                  >
                    Set Response Deadline*
                  </p>
                  <p class="tw-text-left tw-text-sm tw-text-white">
                    Your crew has until this date to join your trip.
                  </p>
                  <v-expansion-panels class="tw-rounded-md">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div
                          class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                        >
                          <v-icon>mdi-calendar-blank</v-icon>
                          <div
                            class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                          >
                            <span v-if="tripData.rsvpDeadline">{{
                              getFormattedDate(
                                tripData.rsvpDeadline,
                                "without-time"
                              )
                            }}</span>
                            <span v-else>Choose a date</span>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-date-picker
                          width="230"
                          class="tw-py-4"
                          no-title
                          color="secondary"
                          v-model="tripData.rsvpDeadline"
                          :error-messages="errors"
                          :min="minDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="secondary"
                            class="tw-tracking-normal"
                            @click="tripData.rsvpDeadline = null"
                            >Reset Date</v-btn
                          >
                        </v-date-picker>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </validation-provider>
                <p
                  class="tw-text-white tw-font-semibold tw-text-xs tw-text-left tw-mt-10 tw-mb-0"
                >
                  How to Edit Trip Info on Invite
                </p>
                <p
                  class="tw-text-white tw-font-normal tw-text-sm tw-text-left tw-mb-10"
                >
                  Go to the trip hub to update dates and destination. Any
                  changes will automatically reflect with your invite URL.
                </p>
                <v-btn
                  :disabled="invalid"
                  type="submit"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-if="trip.isPremium"
                  >{{ isEditMode ? "Save" : "Create Invitation" }}</v-btn
                >
                <v-btn
                  :disabled="invalid"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-else
                  @click="upgradeTrip()"
                  >Upgrade Trip and Save Invite</v-btn
                >
                <button
                  class="btn-tertiary tw-text-white tw-underline tw-tracking-normal tw-mx-auto tw-block tw-mt-4"
                  @click="skipCreation"
                >
                  Skip and Create Later
                </button>
              </div>
            </div>
          </template>
          <template v-if="activeDesign === designs.PINK_MIMOSA">
            <div class="tw-bg-mimosa tw-pt-14">
              <img
                src="@/assets/svgs/designs/pink-mimosa-invited.svg"
                alt="You're invited"
                class="tw-mx-auto tw-block tw-pb-14"
              />
              <div
                class="tw-p-2 tw-bg-white tw-border-pink tw-border-5 tw-border-solid tw-mx-4"
              >
                <div
                  class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-border-pink tw-border-3 tw-border-solid"
                >
                  <img
                    src="@/assets/svgs/designs/pink-mimosa-glass.svg"
                    alt="Pink Mimosa glass"
                    class="tw-w-24 tw-my-6 tw-mx-auto"
                  />
                  <p
                    for="trip"
                    class="tw-text-mimosa-black tw-my-2 tw-font-semibold"
                  >
                    Trip
                  </p>
                  <validation-provider v-slot="{ errors }" ref="tripTitle">
                    <j-text-field
                      v-if="trip"
                      v-model="trip.title"
                      centered-label="true"
                      :error-messages="errors"
                      background-color="#fafafa"
                      :customClasses="['tw-text-center']"
                    />
                  </validation-provider>
                  <p
                    class="tw-text-mimosa-black tw-mt-6 tw-mb-2 tw-font-semibold"
                  >
                    Message
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Message"
                    rules="max:250"
                  >
                    <j-textarea
                      v-model="tripData.message"
                      outlined
                      rounded
                      background-color="#fafafa"
                      :error-messages="errors"
                      :placeholder="placeholderMessage"
                      @focus="placeholderMessage = ''"
                    />
                  </validation-provider>
                  <p
                    class="tw-text-mimosa-black tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Hosted by
                  </p>
                  <p
                    class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mb-0"
                  >
                    {{ tripData.fromName }}
                  </p>

                  <p
                    class="tw-text-mimosa-black tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Date
                  </p>
                  <p
                    class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mb-4"
                  >
                    {{
                      trip && trip.startDate && trip.endDate
                        ? getFormattedDateRange(trip.startDate, trip.endDate)
                        : placeholderText
                    }}
                  </p>
                  <p
                    class="tw-text-mimosa-black tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Destination
                  </p>
                  <p
                    class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mb-0"
                    v-if="destination"
                  >
                    {{ destination ? destination : placeholderText }}
                  </p>
                </div>
              </div>
              <div class="tw-mx-12 tw-mt-14 tw-pb-4">
                <validation-provider v-slot="{ errors }" rules="required">
                  <p
                    class="tw-text-left tw-text-mimosa-black tw-font-semibold tw-mb-0"
                  >
                    Set Response Deadline*
                  </p>
                  <p class="tw-text-left tw-text-sm tw-text-mimosa-black">
                    Your crew has until this date to join your trip.
                  </p>
                  <v-expansion-panels class="tw-rounded-md">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div
                          class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                        >
                          <v-icon>mdi-calendar-blank</v-icon>
                          <div
                            class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                          >
                            <span v-if="tripData.rsvpDeadline">{{
                              getFormattedDate(
                                tripData.rsvpDeadline,
                                "without-time"
                              )
                            }}</span>
                            <span v-else>Choose a date</span>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-date-picker
                          width="230"
                          class="tw-py-4"
                          no-title
                          color="secondary"
                          v-model="tripData.rsvpDeadline"
                          :error-messages="errors"
                          :min="minDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="secondary"
                            class="tw-tracking-normal"
                            @click="tripData.rsvpDeadline = null"
                            >Reset Date</v-btn
                          >
                        </v-date-picker>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </validation-provider>
                <p
                  class="tw-text-mimosa-black tw-font-semibold tw-text-xs tw-text-left tw-mt-10 tw-mb-0"
                >
                  How to Edit Trip Info on Invite
                </p>
                <p
                  class="tw-text-mimosa-black tw-font-normal tw-text-sm tw-text-left tw-mb-10"
                >
                  Go to the trip hub to update dates and destination. Any
                  changes will automatically reflect with your invite URL.
                </p>
                <v-btn
                  :disabled="invalid"
                  type="submit"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-if="trip.isPremium"
                  >{{ isEditMode ? "Save" : "Create Invitation" }}</v-btn
                >
                <v-btn
                  :disabled="invalid"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-else
                  @click="upgradeTrip()"
                  >Upgrade Trip and Save Invite</v-btn
                >
                <button
                  class="btn-tertiary tw-text-mimosa-black tw-tracking-normal tw-mx-auto tw-block tw-mt-4"
                  @click="skipCreation"
                >
                  Skip and Create Later
                </button>
              </div>
            </div>
          </template>
          <template v-if="activeDesign === designs.BLUE_PALM">
            <div class="tw-bg-white tw-pt-14">
              <img
                src="@/assets/svgs/designs/blue-palm-invited.svg"
                alt="You're invited"
                class="tw-mx-auto tw-block tw-pb-14"
              />
              <div
                class="tw-p-2 tw-bg-white tw-border-blue-palm tw-border-5 tw-border-solid tw-mx-4"
              >
                <div
                  class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-border-blue-palm tw-border-3 tw-border-solid"
                >
                  <img
                    src="@/assets/svgs/designs/blue-palms.svg"
                    alt="Blue Palms"
                    class="tw-w-24 tw-my-6 tw-mx-auto"
                  />
                  <p
                    for="trip"
                    class="tw-text-mimosa-black tw-my-2 tw-font-semibold"
                  >
                    Trip
                  </p>
                  <validation-provider v-slot="{ errors }" ref="tripTitle">
                    <j-text-field
                      v-if="trip"
                      v-model="trip.title"
                      centered-label="true"
                      :error-messages="errors"
                      background-color="#fafafa"
                      :customClasses="['tw-text-center']"
                    />
                  </validation-provider>
                  <p
                    class="tw-text-mimosa-black tw-mt-6 tw-mb-2 tw-font-semibold"
                  >
                    Message
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Message"
                    rules="max:250"
                  >
                    <j-textarea
                      v-model="tripData.message"
                      outlined
                      rounded
                      background-color="#fafafa"
                      :error-messages="errors"
                      :placeholder="placeholderMessage"
                      @focus="placeholderMessage = ''"
                    />
                  </validation-provider>
                  <p
                    class="tw-text-mimosa-black tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Hosted by
                  </p>
                  <p
                    class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mb-0"
                  >
                    {{ tripData.fromName }}
                  </p>

                  <p
                    class="tw-text-mimosa-black tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Date
                  </p>
                  <p
                    class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mb-4"
                  >
                    {{
                      trip && trip.startDate && trip.endDate
                        ? getFormattedDateRange(trip.startDate, trip.endDate)
                        : placeholderText
                    }}
                  </p>
                  <p
                    class="tw-text-mimosa-black tw-mt-6 tw-mb-0 tw-font-semibold"
                  >
                    Destination
                  </p>
                  <p
                    class="tw-text-22 tw-text-mimosa-black tw-font-semibold tw-mb-0"
                    v-if="destination"
                  >
                    {{ destination ? destination : placeholderText }}
                  </p>
                </div>
              </div>
              <div class="tw-mx-12 tw-mt-14 tw-pb-4">
                <validation-provider v-slot="{ errors }" rules="required">
                  <p
                    class="tw-text-left tw-text-mimosa-black tw-font-semibold tw-mb-0"
                  >
                    Set Response Deadline*
                  </p>
                  <p class="tw-text-left tw-text-sm tw-text-mimosa-black">
                    Your crew has until this date to join your trip.
                  </p>
                  <v-expansion-panels class="tw-rounded-md">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div
                          class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                        >
                          <v-icon>mdi-calendar-blank</v-icon>
                          <div
                            class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                          >
                            <span v-if="tripData.rsvpDeadline">{{
                              getFormattedDate(
                                tripData.rsvpDeadline,
                                "without-time"
                              )
                            }}</span>
                            <span v-else>Choose a date</span>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-date-picker
                          width="230"
                          class="tw-py-4"
                          no-title
                          color="secondary"
                          v-model="tripData.rsvpDeadline"
                          :error-messages="errors"
                          :min="minDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="secondary"
                            class="tw-tracking-normal"
                            @click="tripData.rsvpDeadline = null"
                            >Reset Date</v-btn
                          >
                        </v-date-picker>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </validation-provider>
                <p
                  class="tw-text-mimosa-black tw-font-semibold tw-text-xs tw-text-left tw-mt-10 tw-mb-0"
                >
                  How to Edit Trip Info on Invite
                </p>
                <p
                  class="tw-text-mimosa-black tw-font-normal tw-text-sm tw-text-left tw-mb-10"
                >
                  Go to the trip hub to update dates and destination. Any
                  changes will automatically reflect with your invite URL.
                </p>
                <v-btn
                  :disabled="invalid"
                  type="submit"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-if="trip.isPremium"
                  >{{ isEditMode ? "Save" : "Create Invitation" }}</v-btn
                >
                <v-btn
                  :disabled="invalid"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-else
                  @click="upgradeTrip()"
                  >Upgrade Trip and Save Invite</v-btn
                >
                <button
                  class="btn-tertiary tw-text-mimosa-black tw-tracking-normal tw-mx-auto tw-block tw-mt-4"
                  @click="skipCreation"
                >
                  Skip and Create Later
                </button>
              </div>
            </div>
          </template>
          <template v-if="activeDesign === designs.DEFAULT">
            <div class="tw-px-10 tw-pb-36 j-star-bg-small">
              <h1
                class="tw-font-perfectly-nineties tw-text-center tw-text-86 tw-text-dark-green tw-mb-12 tw-leading-none"
              >
                You're invited
              </h1>
              <validation-provider
                v-slot="{ errors }"
                name="Message"
                rules="max:250"
              >
                <j-textarea
                  v-model="tripData.message"
                  outlined
                  rounded
                  background-color="#fafafa"
                  :error-messages="errors"
                  label="Invitation Message"
                  :placeholder="placeholderMessage"
                  @focus="placeholderMessage = ''"
                />
              </validation-provider>
            </div>
            <div
              class="tw-text-left tw-relative tw-bg-chartreuse tw-p-10 tw-w-full"
            >
              <div class="tw--mt-36 tw-relative">
                <div class="j-rsvp-top"></div>
                <div class="j-ticket-item tw-px-10 tw-pt-9 tw-bg-white">
                  <p
                    class="tw-text-light-grey tw-font-semibold tw-text-base tw-mb-2"
                  >
                    Trip name
                  </p>
                  <validation-provider v-slot="{ errors }" ref="tripTitle">
                    <j-text-field
                      v-if="trip"
                      v-model="trip.title"
                      :error-messages="errors"
                      background-color="#fafafa"
                      :customClasses="['tw-text-left']"
                    />
                  </validation-provider>
                </div>
                <div class="j-ticket-item tw-px-10 tw-py-9 tw-bg-white">
                  <p
                    class="tw-text-light-grey tw-font-semibold tw-text-base tw-mb-0"
                  >
                    Hosted by
                  </p>
                  <p
                    class="tw-text-20 tw-text-charcoal tw-font-semibold tw-mb-0"
                  >
                    {{ tripData.fromName }}
                  </p>
                </div>
                <div class="j-ticket-item tw-px-10 tw-pb-9 tw-bg-white">
                  <p
                    class="tw-text-light-grey tw-font-semibold tw-text-base tw-mb-0"
                  >
                    Date
                  </p>
                  <p
                    class="tw-text-20 tw-text-charcoal tw-font-semibold tw-mb-0"
                  >
                    {{
                      trip && trip.startDate && trip.endDate
                        ? getFormattedDateRange(trip.startDate, trip.endDate)
                        : placeholderText
                    }}
                  </p>
                </div>
                <div class="j-ticket-item tw-px-10 tw-pb-9 tw-bg-white">
                  <p
                    class="tw-text-light-grey tw-font-semibold tw-text-base tw-mb-0"
                  >
                    Destination
                  </p>
                  <p
                    class="tw-text-20 tw-text-charcoal tw-font-semibold tw-mb-0"
                    v-if="destination"
                  >
                    {{ destination }}
                  </p>
                  <p
                    v-else
                    class="tw-text-20 tw-text-charcoal tw-font-semibold tw-mb-0"
                  >
                    {{ placeholderText }}
                  </p>
                </div>
                <div class="j-ticket-item tw-px-10 tw-pb-9 tw-bg-white">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <p
                      class="tw-text-light-grey tw-font-semibold tw-text-base tw-mb-2"
                    >
                      Set response deadline *
                    </p>
                    <span
                      class="j-text-field-label tw-font-normal tw-text-light-grey tw--mt-2 tw-mb-4"
                      >Your crew has until this date to join your trip.</span
                    >
                    <v-expansion-panels class="tw-rounded-md">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <div
                            class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                          >
                            <v-icon>mdi-calendar-blank</v-icon>
                            <div
                              class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                            >
                              <span v-if="tripData.rsvpDeadline">{{
                                getFormattedDate(
                                  tripData.rsvpDeadline,
                                  "without-time"
                                )
                              }}</span>
                              <span v-else>Choose a date</span>
                            </div>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-date-picker
                            width="230"
                            class="tw-py-4"
                            no-title
                            color="secondary"
                            v-model="tripData.rsvpDeadline"
                            :error-messages="errors"
                            :min="minDate"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="secondary"
                              class="tw-tracking-normal"
                              @click="tripData.rsvpDeadline = null"
                              >Reset Date</v-btn
                            >
                          </v-date-picker>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </validation-provider>
                </div>
                <div class="j-rsvp-bottom"></div>
                <p
                  class="tw-text-charcoal tw-font-normal tw-text-center tw-my-10"
                >
                  Change of plans? Go to the trip hub to updates dates and
                  destination.
                </p>
                <v-btn
                  :disabled="invalid"
                  type="submit"
                  color="secondary"
                  class="tw-tracking-normal"
                  :class="{
                    'j-premium tw-text-white': trip.isPremium && !invalid
                  }"
                  block
                  x-large
                  rounded
                  >{{ isEditMode ? "Save" : "Create Invitation" }}</v-btn
                >
                <button
                  class="btn-tertiary tw-tracking-normal tw-mx-auto tw-block tw-mt-4"
                  @click="skipCreation"
                >
                  Skip and Create Later
                </button>
              </div>
            </div>
          </template>
          <template v-if="activeDesign === designs.RIP">
            <div class="tw-bg-black tw-pt-14">
              <img
                src="@/assets/svgs/designs/rip-invited.svg"
                alt="You're invited"
                class="tw-mx-auto tw-block tw-pb-14"
              />
              <div class="tw-p-2 tw-bg-white tw-mx-4">
                <div
                  class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-border-black tw-border-8 tw-border-solid"
                >
                  <img
                    src="@/assets/svgs/designs/rip-graphic.svg"
                    alt="Rip"
                    class="tw-my-6 tw-mx-auto"
                  />
                  <p for="trip" class="tw-text-black tw-my-2 tw-font-semibold">
                    Trip
                  </p>
                  <validation-provider v-slot="{ errors }" ref="tripTitle">
                    <j-text-field
                      v-if="trip"
                      v-model="trip.title"
                      centered-label="true"
                      :error-messages="errors"
                      background-color="#fafafa"
                      :customClasses="['tw-text-center']"
                    />
                  </validation-provider>
                  <p class="tw-text-black tw-mt-6 tw-mb-2 tw-font-semibold">
                    Message
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Message"
                    rules="max:250"
                  >
                    <j-textarea
                      v-model="tripData.message"
                      outlined
                      rounded
                      background-color="#fafafa"
                      :error-messages="errors"
                      :placeholder="placeholderMessage"
                      @focus="placeholderMessage = ''"
                    />
                  </validation-provider>
                  <p class="tw-text-black tw-mt-6 tw-mb-0 tw-font-semibold">
                    Hosted by
                  </p>
                  <p class="tw-text-22 tw-text-black tw-font-semibold tw-mb-0">
                    {{ tripData.fromName }}
                  </p>

                  <p class="tw-text-black tw-mt-6 tw-mb-0 tw-font-semibold">
                    Date
                  </p>
                  <p class="tw-text-22 tw-text-black tw-font-semibold tw-mb-4">
                    {{
                      trip && trip.startDate && trip.endDate
                        ? getFormattedDateRange(trip.startDate, trip.endDate)
                        : placeholderText
                    }}
                  </p>
                  <p class="tw-text-black tw-mt-6 tw-mb-0 tw-font-semibold">
                    Destination
                  </p>
                  <p
                    class="tw-text-22 tw-text-black tw-font-semibold tw-mb-0"
                    v-if="destination"
                  >
                    {{ destination ? destination : placeholderText }}
                  </p>
                </div>
              </div>
              <div class="tw-mx-12 tw-mt-14 tw-pb-4">
                <validation-provider v-slot="{ errors }" rules="required">
                  <p
                    class="tw-text-left tw-text-white tw-font-semibold tw-mb-0"
                  >
                    Set Response Deadline*
                  </p>
                  <p class="tw-text-left tw-text-sm tw-text-white">
                    Your crew has until this date to join your trip.
                  </p>
                  <v-expansion-panels class="tw-rounded-md">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div
                          class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                        >
                          <v-icon>mdi-calendar-blank</v-icon>
                          <div
                            class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                          >
                            <span v-if="tripData.rsvpDeadline">{{
                              getFormattedDate(
                                tripData.rsvpDeadline,
                                "without-time"
                              )
                            }}</span>
                            <span v-else>Choose a date</span>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-date-picker
                          width="230"
                          class="tw-py-4"
                          no-title
                          color="secondary"
                          v-model="tripData.rsvpDeadline"
                          :error-messages="errors"
                          :min="minDate"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="secondary"
                            class="tw-tracking-normal"
                            @click="tripData.rsvpDeadline = null"
                            >Reset Date</v-btn
                          >
                        </v-date-picker>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </validation-provider>
                <p
                  class="tw-text-white tw-font-semibold tw-text-xs tw-text-left tw-mt-10 tw-mb-0"
                >
                  How to Edit Trip Info on Invite
                </p>
                <p
                  class="tw-text-white tw-font-normal tw-text-sm tw-text-left tw-mb-10"
                >
                  Go to the trip hub to update dates and destination. Any
                  changes will automatically reflect with your invite URL.
                </p>
                <v-btn
                  :disabled="invalid"
                  type="submit"
                  class="tw-tracking-normal rip-button"
                  :class="{
                    'j-premium tw-text-white': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-if="trip.isPremium"
                  >{{ isEditMode ? "Save" : "Create Invitation" }}</v-btn
                >
                <v-btn
                  :disabled="invalid"
                  class="tw-tracking-normal rip-button tw-text-white"
                  :class="{
                    'j-premium': !invalid
                  }"
                  block
                  x-large
                  rounded
                  v-else
                  @click="upgradeTrip()"
                  >Upgrade Trip and Save Invite</v-btn
                >
                <button
                  class="btn-tertiary tw-text-white tw-tracking-normal tw-mx-auto tw-block tw-mt-4 tw-border-white"
                  @click="skipCreation"
                >
                  Skip and Create Later
                </button>
              </div>
            </div>
          </template>
        </v-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import jettyLogo from "@/assets/lets-jetty.svg";
import Header from "@/components/Header";
import { DESIGNS } from "@/enums/designs.js";

export default {
  name: "InviteCrewForm",
  mixins: [FormattedDateRange],
  components: {
    Header
  },
  data() {
    return {
      designs: DESIGNS,
      activeDesign: DESIGNS.DEFAULT,
      date: DateTime.now(),
      minDate: DateTime.local().toISODate(),
      startDate: null,
      endDate: null,
      uuid: "",
      placeholderMessage:
        "Hi everyone, join me on Let's Jetty to plan our trip! We can figure out details together but let me know if you're interested.",
      tripData: {
        tripId: "",
        message: "",
        rsvpDeadline: null,
        fromName: ""
      },
      placeholderText: "We'll decide together"
    };
  },
  computed: {
    session() {
      return this.$store.state.auth.user;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isEditMode() {
      return (
        sessionStorage.getItem("editingInvite") || this.$route.params.inviteId
      );
    },
    destination() {
      if (this.trip && this.trip.destinations && this.trip.destinations[0])
        return this.trip.destinations[0].name;
      return "TBD";
    },
    defaultHeaderDisabled() {
      return this.$store.state.meta.header.defaultHeaderDisabled;
    },
    pageTitle() {
      return this.$store.state.meta.header.pageTitle;
    },
    bgColor() {
      return this.$store.state.meta.header.bgColor;
    },
    fontColor() {
      return this.$store.state.meta.header.fontColor;
    },
    tagline() {
      return this.$store.state.meta.header.tagline;
    },
    iconOne() {
      return this.$store.state.meta.header.iconOne;
    },
    iconTwo() {
      return this.$store.state.meta.header.iconTwo;
    },
    mainBgColor() {
      return this.$store.state.meta.style.mainBgColor;
    },
    globalAlert() {
      return this.$store.state.meta.globalAlert;
    }
  },
  methods: {
    upgradeTrip() {
      //save invitation to local storage
      localStorage.setItem("tripData", JSON.stringify(this.tripData));
      localStorage.setItem("template", this.activeDesign);
      this.$router.push({ name: "UpgradeTripRoute" });
    },
    save() {
      this.isEditMode ? this.editInvitation() : this.createInvitation();
    },
    setPresets() {
      if (this.isEditMode) {
        this.$store.commit("meta/setHeader", {
          defaultHeaderDisabled: false,
          pageTitle: "Update Invite",
          bgColor: "#E6FFA1",
          fontColor: "#203848",
          tagline: null,
          iconOne: null,
          iconTwo: null,
          showBackButton: true
        });
      } else {
        this.$store.commit("meta/setHeader", {
          defaultHeaderDisabled: false,
          pageTitle: "Create Invite",
          bgColor: "#E6FFA1",
          fontColor: "#203848",
          tagline: null,
          iconOne: "mdi-bag-suitcase",
          iconTwo: jettyLogo,
          showBackButton: false
        });
      }
      const firstName = this.session.firstName;
      const lastName = this.session.lastName;
      if (firstName) {
        this.tripData.fromName = `${firstName} ${lastName}`;
      } else {
        this.tripData.fromName = null;
      }

      //get trip invitation object
      if (
        this.$route.params.inviteId ||
        sessionStorage.getItem("editingInvite")
      ) {
        let inviteId =
          this.$route.params.inviteId ||
          sessionStorage.getItem("editingInvite");
        this.$store
          .dispatch("trip-invitation/get", [
            inviteId,
            {
              query: {
                include: "destination,users,survey,trip_invitation"
              }
            }
          ])
          .then((res) => {
            this.tripData.fromName = res ? res.fromName : "";
            this.tripData.message = res ? res.message : "";
            this.activeDesign =
              res && res.template ? res.template : this.designs.DEFAULT;
            let date = DateTime.now();
            if (res && res.rsvpDeadline) {
              date = DateTime.fromISO(res.rsvpDeadline, {
                setZone: true
              }).toISODate();
            }
            this.tripData.rsvpDeadline = res
              ? DateTime.fromISO(date, { setZone: true }).toISODate()
              : null;
          })
          .catch((error) => {
            console.error("Error fetching trip invitation details:", error);
          });
      }
    },
    async createInvitation() {
      this.updateTripTitle();
      this.tripData.tripId = this.trip.id;
      if (this.tripData.message.length === 0)
        this.tripData.message = this.placeholderMessage;
      this.$store
        .dispatch("trip-invitation/create", {
          ...this.tripData,
          template: this.activeDesign,
          rsvpDeadline: DateTime.fromFormat(
            this.tripData.rsvpDeadline,
            "yyyy-MM-dd",
            {
              setZone: true
            }
          )
            .endOf("day")
            .toISODate()
        })
        .then((res) => {
          const now = DateTime.now();
          const start = DateTime.fromISO(this.$store.state.auth.user.createdAt);
          const diff = start.diff(now, "days").toObject();
          const count = Math.abs(Math.round(diff.days));
          let newUser = false;
          if (count <= 7) {
            newUser = true;
          }

          window.dataLayer.push({
            event: "create_trip_invite",
            uuid: this.$store.state.auth.user.uuid,
            signup_date: DateTime.fromISO(
              this.$store.state.auth.user.createdAt
            ).toISO(),
            new_user: newUser,
            verified_user: this.$store.state.auth.user.isEmailVerified,
            sign_up_trip_id: this.$store.state.auth.user.signupTripId,
            page_location: localStorage.getItem("previous_route"),
            trip_invite_id: res.id,
            response_deadline: res.rsvpDeadline,
            survey_attached:
              this.trip.surveys && this.trip.surveys.length > 0 ? true : false
          });

          this.uuid = res.uuid;
          this.$router.push({
            name: "CrewListRoute",
            params: { uuid: this.uuid, inviteId: res.id }
          });
        })
        .catch((error) => {
          console.error("Error creating invitation:", error);
        });
    },
    async editInvitation() {
      this.updateTripTitle();
      this.tripData.tripId = this.trip.id;
      this.$store
        .dispatch("trip-invitation/patch", [
          this.trip.trip_invitation.uuid,
          {
            uuid: this.trip.trip_invitation.uuid,
            template: this.activeDesign,
            tripId: this.tripData.tripId,
            message: this.tripData.message,
            rsvpDeadline: DateTime.fromFormat(
              this.tripData.rsvpDeadline,
              "yyyy-MM-dd",
              {
                setZone: true
              }
            )
              .endOf("day")
              .toISODate(),
            fromName: this.tripData.fromName
          }
        ])
        .then((res) => {
          this.uuid = res.uuid;
          this.$router.push({
            name: "CrewListRoute",
            params: { uuid: this.uuid, inviteId: res.id }
          });
        })
        .catch((error) => {
          console.error("Error editing invitation:", error);
        });
    },
    async updateTripTitle() {
      const isPristine = this.$refs.tripTitle.flags.pristine;
      if (!isPristine) {
        await this.$store.dispatch("trip/patch", [
          this.trip.id,
          {
            title: this.trip.title
          }
        ]);
      }
    },
    skipCreation() {
      this.$router.push({
        name: "TripViewRoute",
        params: { id: this.trip.id }
      });
    }
  },
  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Send Invite",
      bgColor: "#E6FFA1",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });

    if (!this.trip) {
      // Dispatch the action to get trip details
      this.$store
        .dispatch("trip/get", [
          this.$route.params.id,
          {
            query: {
              include: "destination,users,survey,trip_invitation"
            }
          }
        ])
        .then((res) => {
          this.tripData.tripId = res.id;
        });
    }

    if (this.$route.params.inviteId) {
      sessionStorage.setItem("editingInvite", this.$route.params.inviteId);
    }

    if (localStorage.getItem("tripData")) {
      this.tripData = JSON.parse(localStorage.getItem("tripData"));
      this.activeDesign = localStorage.getItem("template");
      localStorage.removeItem("tripData");
      localStorage.removeItem("template");
    }
  },

  mounted() {
    this.setPresets();
  },
  destroyed() {
    sessionStorage.removeItem("editingInvite");
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panel::before {
  @apply tw-shadow-custom;
}
.v-expansion-panel-header--active .j-panel-header .v-icon {
  @apply tw-text-dark-green;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg.rip-button {
  background-color: grey !important;
  color: #fff !important;
  opacity: 0.6 !important;
}
</style>
